import { Button, Modal } from 'antd';
import React from 'react';

const ApprovalTypeConfirmModal = ({ showModal, handleOk, handleCancel }) => {
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        footer={null}
        closable={false}
        width={400}
      >
        <div>
          <h2>CAUTION</h2>
          <p>
            Shifting approval from remote to on-site will remove all the
            external approvers assigned
          </p>
          <p>Are you sure?</p>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              No
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleOk}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ApprovalTypeConfirmModal;
